





























































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import DialogComponent from '@/components/dialog.component.vue';
import Toaster from '@/mixins/toaster.mixin';

@Component({
  components: {
    VueCropper,
    DialogComponent,
  },
})
export default class VueCropperComponent extends mixins(Toaster) {
  mimeType = '';

  deletedImage = false;

  cropedImage = null;

  fileSize = 0;

  autoCrop = false;

  selectedFile: string | ArrayBuffer | null | undefined = '';

  image = '';

  dialog = false;

  files = '';

  @Prop() readonly userImg!: { type: string; data: [] } | null;

  @Prop() readonly username!: string;

  cropImg() {
    this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();

    this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
      this.fileSize = blob.size;
      this.imgSizeValidation();
    });

    this.$emit('updateImg', { deleted: false });
    this.deletedImage = false;
  }

  $refs!: {
    cropper: VueCropper;
  };

  bufferToBase64(buf) {
    const binstr = Array.prototype.map
      .call(buf, (ch) => String.fromCharCode(ch))
      .join('');
    return btoa(binstr);
  }

  get getUserImage() {
    if (this.userImg && !this.deletedImage) {
      this.fileSize = this.userImg.data.length;
      this.imgSizeValidation();
      return this.bufferToBase64(this.userImg.data);
    }
    return null;
  }

  onFileSelect(e) {
    const file = e.target.files[0];
    [, this.mimeType] = file.type.split('/');
    // this.fileSize = file.size;

    if (this.FileReaderValidation() && this.imgTypeValidation()) {
      this.dialog = true;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.selectedFile = event?.target?.result;
        this.$refs.cropper.replace(this.selectedFile);
      };
      reader.readAsDataURL(file);
      e.target.value = null;
    }
  }

  FileReaderValidation() {
    if (typeof FileReader !== 'function') {
      this.$emit('uploadfailed', { msg: 'fileReaderValidationError' });
      this.dialog = false;
      return false;
    }
    return true;
  }

  imgTypeValidation() {
    if (
      !(
        this.mimeType === 'jpg'
        || this.mimeType === 'png'
        || this.mimeType === 'jpeg'
      )
    ) {
      this.$emit('uploadfailed', { msg: 'imgTypeValidationError' });
      this.dialog = false;
      return false;
    }
    return true;
  }

  imgSizeValidation() {
    if (this.fileSize > 1048576) {
      this.$emit('uploadfailed', { msg: 'imgSizeValidationError' });
      this.dialog = false;
      return false;
    }
    this.$emit('uploadfailed', { msg: null });
    return true;
  }

  deleteUserImage() {
    this.cropedImage = null;
    this.selectedFile = null;
    this.deletedImage = true;
    this.$emit('updateImg', { deleted: true });
  }
}
