




































































import { Component, Vue } from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';

@Component({
  components: {
    DialogComponent,
  },
})
export default class ChangePasswordDialog extends Vue {
  showConfirmationDialog = false;

  currentPassword = '';

  newPassword = '';

  confirmPassword = '';

  valid = false;

  hideCurrentPassword = true;

  hideNewPassword = true;

  hideConfirmPassword = true;

  passwordRules = [(v: string) => !!v || this.$t('requiredField')];

  newpasswordRules = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => (v && v.length <= 50) || `${this.$t('maxLetters')} 50`,
    (v: string) => (v && this.validatePAss(v)) || this.$t('WEAK_PASSWORD'),
  ];

  confirmPasswordRoles = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => v === this.newPass() || this.$t('NOT_CONFIRMED'),
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  open() {
    this.showConfirmationDialog = true;
    this.hideCurrentPassword = true;
    this.hideNewPassword = true;
    this.hideConfirmPassword = true;
  }

  newPass() {
    return this.newPassword;
  }

  onConfirmClick() {
    if (!this.$refs.form.validate()) {
      return;
    }
    this.$emit('confirm', {
      newPassword: this.newPassword,
      currentPassword: this.currentPassword,
    });
    this.close();
  }

  close() {
    this.newPassword = '';
    this.currentPassword = '';
    this.confirmPassword = '';
    this.showConfirmationDialog = false;
    this.$refs.form.reset();
  }

  validatePAss(v) {
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
    return regExp.test(v);
  }
}
