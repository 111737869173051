












































































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import HomeProvider from '@/providers/home.provider';
import changePasswordDialog from '@/components/profile/changePassword.dialog.vue';
import DialogComponent from '@/components/dialog.component.vue';
import { Lang, ProfileData } from '../../types/general.type';
import Toaster from '../../mixins/toaster.mixin';
import VueCropperComponent from './uploadCroppperImg.component.vue';

@Component({
  components: {
    changePasswordDialog,
    DialogComponent,
    VueCropperComponent,
  },
})
export default class ProfileComponent extends mixins(Toaster) {
  valid = true;

  loading = true;

  resErr = '';

  showBackConfirm = false;

  updatedImage = false;

  foundation = '';

  showSaveConfirm = false;

  err = '';

  profileData: ProfileData = {
    name: { en: '', ar: '' },
    nationalId: '',
    email: '',
    foundation: '',
    endDate: '',
    userName: '',
  };

  deletedImg = false;

  userName: string = this.$route?.params.username;

  homeProvider = new HomeProvider();

  $refs!: {
    passwordDialog: changePasswordDialog;
    form: HTMLFormElement;
    updateImg: VueCropperComponent;
  };

  rules = {
    name: [
      (v: string) => !!v.trim() || this.$t('requiredField'),
      (v: string) => (v.trim() && v.trim().length <= 150) || `${this.$t('maxLetters')} 150`,
      (v: string) => /^[A-Za-z\u0621-\u064A0-9 ]*$/.test(v.trim())
        || this.$t('invalidInputData'),
    ],

    userName: [
      (v: string) => !!v.trim() || this.$t('requiredField'),
      (v: string) => (v.trim() && v.trim().length >= 3)
        || this.$t('validations.minLength', { min: 3 }),
    ],
    email: [
      (v: string) => !!v || this.$t('requiredField'),
      (v: string) => (v && v.length <= 100)
        || this.$t('validations.maxLength', { max: 100 }),
      (v: string) => /.+@.+\..+/.test(v) || this.$t('invalidInputData'),
    ],
    foundation: [
      (v: string) => v.length <= 50 || this.$t('validations.maxLength', { max: 50 }),
    ],
  };

  mounted() {
    this.loadProfileData();
  }

  async loadProfileData() {
    try {
      this.profileData = await this.homeProvider.getProfileData(this.userName);
      this.foundation = this.servertranslate(this.profileData.foundation);
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.resErr = err;
    }
  }

  bufferToBase64(buf) {
    const binstr = Array.prototype.map
      .call(buf, (ch) => String.fromCharCode(ch))
      .join('');
    return btoa(binstr);
  }

  openChangePasswordDialog() {
    this.$refs.passwordDialog.open();
  }

  updateProfile() {
    try {
      const formData = new FormData();
      if (this.$refs.form.validate()) {
        formData.append('nameAr', this.profileData.name.ar);
        formData.append('nameEn', this.profileData.name.en);
        formData.append('email', this.profileData.email);
        formData.append('userNameNew', this.profileData.userName);

        if (this.profileData.canEditFoundation) {
          formData.append('foundation', this.foundation);
        }

        if (!this.updatedImage) {
          formData.append('updateImage', '0');
        } else {
          formData.append('updateImage', '1');
          if (this.deletedImg) {
            formData.append('image', '');
          } else {
            this.$refs.updateImg.$refs.cropper
              .getCroppedCanvas()
              .toBlob((blob) => {
                formData.append('image', blob);
                this.updateUserProfile(formData);
              });
            return;
          }
        }
        this.updateUserProfile(formData);
      }
    } catch (err) {
      this.resErr = err;
    }
  }

  async updateUserProfile(formData: FormData) {
    this.showSaveConfirm = false;
    this.loading = true;
    try {
      const message: Lang = await this.homeProvider.updateProfileData(
        this.userName,
        formData,
      );
      const { username } = this.$store.state.auth;
      this.successToaster(message);
      const newUserName = String(formData.get('userNameNew'));
      if (newUserName) {
        this.updateStoreUserName(newUserName);
        this.$router.replace({
          name: 'profile',
          params: { username: newUserName },
        });
      } else {
        this.$router.replace({
          name: 'profile',
          params: { username },
        });
      }

      this.loading = false;
    } catch (err) {
      this.resErr = err;
      this.loading = false;
    }
  }

  updateStoreUserName(newUserNAme: string) {
    this.$store.commit('updateUserName', newUserNAme);
  }

  changeFoundation(e) {
    this.foundation = e.target;
  }

  async onPasswordChange({ currentPassword, newPassword }) {
    try {
      const message: Lang = await this.homeProvider.changePassword(
        this.userName,
        currentPassword,
        newPassword,
      );
      this.successToaster(message);
    } catch (err) {
      this.resErr = err;
    }
  }

  updateImg({ deleted }) {
    this.updatedImage = true;
    this.deletedImg = deleted;
    this.err = '';
  }

  goBack() {
    const { username } = this.$store.state.auth;

    this.$router.push({
      name: 'profile',
      params: {
        username,
      },
    });
  }

  uploadfailed({ msg }) {
    this.err = msg;
  }

  @Watch('$store.state.lang.lang', { deep: true })
  translateValidations() {
    const el: NodeListOf<Element> = this.$el.querySelectorAll('.error--text');
    if (el.length) {
      this.$refs.form.validate();
    }
  }
}
